

.customer-account-login, 
.customer-account-create, 
.account,
.customer-account-createpassword,
.customer-account-forgotpassword{
    margin-top: 230px;

    .input-text{
        @extend .bg-white;
        @extend .form-simple;
        @extend .border-0;
        @extend .din-r;
        @extend .w-100;
        @extend .fontXX;
        @extend .text-extraLightBlack;
        @extend .py-3;
        @extend .ps-3;
        @extend .mb-2;
        @extend .res14;
    }

    .fieldset.create.account{
        margin-top: 0;
    }

    .field{
        @extend .mb-2;
    }

    .page-title{
        h1{
            font-family: "futura light bt";
        }
    }

    .heading--medium, .block-title, .box-title{
        font-family: "futura light bt";
    }

    .modal-inner-wrap{
        border: 0;
    }
}

.form-address-edit{
    select{
        background-size: 12px;
        background-position: top 22px right 10px;
        background-repeat: no-repeat;
        padding: 10px;
        border: 0;
        height: 50px;
    }
}

.account{
    .table > :not(caption) > * > *{
        background-color: inherit;
        color: $white;
        box-shadow: none;
        font-size: 13px;
    }

    .price{
        font-size: 13px;
    }

    .old-price{
        display: none !important;
    }

    main{
        padding-top: 20px !important;
    }
}

