
.customer-account-login h2 {
    font-family: "league-gothic", sans-serif;
    font-size: 36px;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

.customer-account-create, .customer-account-forgotpassword{
  .page-title{
    text-align: center;
    font-family: "league-gothic", sans-serif;
    font-size: 36px;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
  }

  .form-create-account{
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}


.customer-account-login, .customer-account-create, 
.customer-account-createpassword,
.customer-account-forgotpassword{
  #maincontent{
    padding-top: 100px;
  }

}
